html,
body,
#root {
    height: 100%;
    overflow: hidden;
}

#root {
    display: flex;
    flex-direction: column;
}

.ps {
    overflow: scroll !important;
}

.ps::-webkit-scrollbar {
    display: none;
}


/* .ps__rail-x,
.ps__rail-y {
    z-index: 2000;
} */

.pg-viewer-wrapper .pdf-canvas>canvas {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.rst__rowContents {
    padding-right: 0;
}

.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.Pane {
    overflow: hidden;
}

.unsupported-message {
    padding: 0
}

.data-grid-container .data-grid .cell {
    height: 22px;
    max-width: 320px;
    overflow: hidden;
}

.data-grid-container .data-grid .cell>input {
    width: 100%;
    height: 100%;
}

.scroll-visible .ps__rail-x {
    opacity: 0.6;
}