.pg-viewer-wrapper {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    height: auto !important;
}

.pg-viewer-wrapper .pg-viewer {
    background: #aaa;
}

.pg-viewer-wrapper .pdf-canvas>canvas {
    margin: 10px auto;
    display: block;
}

.pg-viewer-wrapper .pg-viewer .loading-container {
    padding: 10px;
}

#pg-photo-container {
    width: auto !important;
    height: auto !important;
}

#pg-photo-container>img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
}